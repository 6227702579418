<template>
  <div class="pado-app">
    <div class="pado_top" style="background-color:#EEEEEE">
      <div class="top fx fxAlign">
        <img src="@/assets/icon/icon_host18.png" />
        <i class="el-icon-arrow-right elIcon"></i>
        <span v-if="sjkInfo" @click="topRouter">{{sjkInfo.dbName}}简介</span>
        <i class="el-icon-arrow-right elIcon"></i>
        <span>数据库检索</span>
      </div>
    </div>
    <div class="pado_search">
      <div class="top fx fxAlign fxSty">
        <p class="topText" v-if="sjkInfo">{{sjkInfo.dbName}}</p>
        <p class="topText1" v-if="sjkInfo">{{sjkInfo.dbProvider}} {{getTimeFormat_1(sjkInfo.uploadTime)}}</p>
        <el-input
          placeholder="请输入内容"
          style="width:600px;margin-top:40px;"
          v-model="search"
          class="input-with-select"
          @keyup.enter.native="init()"
        >
          <el-button slot="prepend" icon="el-icon-search"></el-button>
          <el-button slot="append" @click="init()">检索</el-button>
        </el-input>
      </div>
    </div>
    <div class="centy" v-if="payal">
      <div class="pado_list">
        <div class="top_list">
          <div class="list_top fx fxSb">
            <div class="top_left fx fxAlign">
              <el-checkbox v-model="selAll" @change="tapSelAll"
                >全选</el-checkbox
              >
              <span style="font-size:14px;color:#333;margin:0 30px;"
                >已选：{{ downLoadNum }}个</span
              >
              <div class="top_btn" @click="tapSeldwoLoad()">
                下载
              </div>
            </div>
            <div class="top_right">共{{ this.tleght }}条资源量</div>
          </div>
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 80%"
        @row-click="clickData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"> </el-table-column>
        <template v-for="(item, index) in title">
          <el-table-column
            :prop="item.dbField"
            :label="item.dbFieldMeaning"
            :key="index"
          ></el-table-column>
        </template>
        <el-table-column label="下载">
          <template slot-scope="scope">
            <el-button
              @click.native.stop="handleClick(scope.row)"
              type="text"
              size="small"
              >原文下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pado_top pado_bottom">
        <div class="top fx fxCenter">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-sizes="[5, 10, 15, 20, 50]"
            :page-size="queryInfo.pagesize"
            prev-text="上一页"
            next-text="下一页"
            layout="total,prev, pager, next"
            :total="tleght"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="centy" v-if="!payal">
      <div class="pado_list">
        <div class="top_list">
          <div class="list_top fx fxSb">
            <div class="top_left fx fxAlign">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <span style="font-size:14px;color:#333;margin:0 30px;"
                >已选：{{ downLoadNum1 }}个</span
              >
              <el-link
                :underline="false"
                style="margin-right:50px"
                @click="delects()"
                >清除</el-link
              >
              <div class="top_btn" @click="mfdownload()">
                下载
              </div>
            </div>
            <div class="top_right">共{{ this.mflegth }}条资源量</div>
          </div>
        </div>
      </div>

      <el-checkbox-group
        v-model="checkedCities"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox
          v-for="city in cities"
          :label="city"
          :key="city.id"
          class="vc"
          ><div class="setcr">
            <p>{{ city.title }}</p>
            <div class="setcrct">{{ city.content }}</div>
          </div>
          <el-link
            type="primary"
            :underline="false"
            @click.native.stop="handleClick(city)"
            >下载原文</el-link
          ></el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div
      class="pado_tc p_f fx fxCenter"
      v-if="isShowTc"
      @click="isShowTc = false"
    >
      <div class="tc_main" @click.stop="">
        <div class="main_back">
          <img
            @click="isShowTc = false"
            src="@/assets/icon/backIcon.png"
            class="backImg"
          />
        </div>
        <div class="tc_text1">
          单次付费
        </div>
        <div class="tc_text2">
          单个原文收费<span> {{ payNum }}个 </span>知识币，本次一次性下载<span>
            {{ downLoadNum }}个 </span
          >源文。
        </div>
        <div class="tc_text2">
          <span>{{ downLoadNum * payNum }}个 </span>知识币
        </div>
        <div class="tc_btn" @click="tapAffirm">
          确认支付
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/services/http.js";
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
import axios from "axios";
import { formatZero, getTimeFormat ,getTimeFormat_1} from "@/js/common/Resoures";
const cityOptions = ["上海", "北京", "广州", "深圳"];
export default {
  data() {
    return {
      id: "", //数据库id
      search: "", //搜索
      sjkInfo:null,
      selAll: false, //是否全选
      isPay: true, //是否付费
      isShowTc: false, //是否显示弹窗
      payNum: 2, //单个文件费用
      list: [],
      title: [],
      downLoadNum: 0, //下载数量
      tleght: 0,
      queryInfo: {
        pagenum: 1, //当前页数
        pagesize: 10, //每页条数
      },
      total: "", //总条目数
      payal: true,
      checkAll: false,
      checkedCities: [],
      cities: "",
      isIndeterminate: true,
      downLoadNum1: 0,
      mflegth: 0,
      getTimeFormat_1:getTimeFormat_1
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.init();
    // this.tleght = this.list.length;
  },
  methods: {
    async init() {
      //获取数据库详情
      let info = await QUERYED(
        "post",
        "",
        'dbInformation( where: {id: {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id dbName dbProvider dbUrl chargeType resourceNum uploadTime datable }"
      );
      if(info.success){
        this.sjkInfo = info.data.dbInformation[0]
      }

      //获取资源
      if (info.data.dbInformation[0].chargeType == "0") {
        this.payal = false;
        const token = localStorage.getItem("Authorization");
        const formData = new FormData();
        let that = this;
        formData.append("dbPath", info.data.dbInformation[0].dbUrl);
        formData.append("table", info.data.dbInformation[0].datable);
        formData.append("val", this.search);
        formData.append("pageIndex", 0);
        formData.append("pageSize", 20);
        await axios
          .post(
            this.api.LoginURL.concat("/common/getByConditions"),
            formData,
            {
              headers: {
                token: ` ${token}`,
              },
            }
          )
          .then((response) => {
            this.cities = response.data.data;
            this.mflegth = response.data.data.length;
          })
          .catch(function(error) {
            console.log("错误" + error);
          });
      }


      let info_table = await QUERYED(
        "post",
        "",
        'dbFieldInformation( where: {dbnam : {_like: "%' +
          info.data.dbInformation[0].dbUrl +
          '%"}},offset: ' +
          0 +
          ") {id dbField  dbFieldMeaning  isTitle  dbindex  isdrive  isserch   }"
      );
      this.title = info_table.data.dbFieldInformation;
      const token = localStorage.getItem("Authorization");
      const formData = new FormData();
      let that = this;
      formData.append("dbPath", info.data.dbInformation[0].dbUrl);
      formData.append("table", info.data.dbInformation[0].datable);
      formData.append("val", this.search);
      formData.append("pageIndex", (that.queryInfo.pagenum - 1) * 10);
      formData.append("pageSize", 10);
      await axios
        .post(
          this.api.LoginURL.concat("/common/getByConditions"),
          formData,

          {
            headers: {
              token: ` ${token}`,
            },
          }
        )
        .then((response) => {
          let data = response.data.data
          this.list = data[1];
          this.tleght = data[0].ResourceNum;
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
      // const formData1 = new FormData();
      // formData1.append("dbPath", info.data.dbInformation[0].dbUrl);
      // formData1.append("table", info.data.dbInformation[0].datable);
      // await axios
      //   .post(
      //     this.api.LoginURL.concat("/common/getResourceNum"),
      //     formData1,

      //     {
      //       headers: {
      //         token: ` ${token}`,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     this.tleght = response.data.data;
      //   })
      //   .catch(function(error) {
      //     console.log("错误" + error);
      //   });
    },



    getList() {
      let fromData = new FormData();
      fromData.append("loginName", loginName);
      fromData.append("rootpath", ftppath);
      post("http://192.168.1.120:9527/common/resolverSqlite", fromData).then(
        (res) => {
          console.log(res, 123456);
        }
      );
    },

    //全选或反向
    tapSelAll(e) {
      this.$refs.multipleTable.toggleAllSelection();
      for (let item of this.list) {
        this.$set(item, "selAll", e);
      }
      this.downLoadNum = e ? this.list.length : 0;
    },

    //判断是否被全选
    onCheckboxUpdate() {
      let num = 0;
      for (let item of this.list) {
        if (item.selAll) num++;
      }
      this.downLoadNum = num;
      this.selAll = num == this.list.length;
    },

    //点击选择下载时
    tapSeldwoLoad() {
      if (this.downLoadNum <= 0) {
        this.$message({
          message: "请选择要下载的原文",
          type: "warning",
        });
        return;
      }
      if (this.isPay) this.isShowTc = true;
      else this.downLoadData();
    },

    //点击单个下载时

    async handleClick(row) {
      const token = localStorage.getItem("Authorization");
      let info = await QUERYED(
        "post",
        "",
        'dbInformation( where: {id: {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id dbName dbProvider dbUrl chargeType resourceNum uploadTime datable fullTextRule fullTextPath }"
      );

      const formData = new FormData();
      formData.append("fullTextRule", info.data.dbInformation[0].fullTextRule);
      formData.append("fullTextPath", info.data.dbInformation[0].fullTextPath);
      formData.append("id", row.id);
      formData.append("dbPath", info.data.dbInformation[0].dbUrl);
      formData.append("table", info.data.dbInformation[0].datable);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post(this.api.LoginURL.concat("/common/down"), formData, {
          responseType: "blob", //服务器返回的数据类型
        })
        .then((res) => {
          console.log(res.data);
          //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
          //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
          //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
          const content = res.data;
          const blob = new Blob([content]); //构造一个blob对象来处理数据
          const fileName =
            row[info.data.dbInformation[0].fullTextRule] + ".docx";
          //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
          //IE10以上支持blob但是依然不支持download
          if ("download" in document.createElement("a")) {
            //支持a标签download的浏览器
            const link = document.createElement("a"); //创建a标签
            link.download = fileName; //a标签添加属性
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click(); //执行下载
            URL.revokeObjectURL(link.href); //释放url
            document.body.removeChild(link); //释放标签
            // updatedownload(this.id, this.download);
            // this.$router.push({
            //   path: "/resources_index",
            //   query: {},
            // });
          } else {
            //其他浏览器
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tapDownOne(item) {
      this.downLoadNum = 1;
      if (this.isPay) this.isShowTc = true;
      else this.downLoadData();
    },
    //点击列表时
    clickData(row, event, column) {
      console.log(row, event, column);

      this.$router.push({
        path: "/dataInfo",
        query: {
          dataId: row.id,
          id: this.id,
        },
      });
    },

    //点击确认时
    async tapAffirm() {
      let { payNum, downLoadNum } = this;
      let pay = downLoadNum * payNum;
      //查询用户剩余知识币
      let totals = await QUERYED(
        "post",
        "",
        `User(where: {id: {_eq: "${this.$store.state.userinfo.id}"}}) {Money}`
      );
      let money = totals.data.User[0].Money * 1;
      if (pay > money) {
        this.$message({
          message: "您的余额不足,请充值",
          type: "warning",
        });
      } else {
        //扣除用户知识币
        let userinfoMoney = await UPDATE(
          "post",
          "",
          `update_User(where: {id: {_eq: "${
            this.$store.state.userinfo.id
          }"}},_set: {Money: ${money - pay}}) {affected_rows}`
        );
        if (userinfoMoney.data.update_User.affected_rows != "1") {
          this.$message({
            message: "付费失败,请重试",
            type: "warning",
          });
          return;
        }
        this.createBill(pay);
        this.createMessage();

        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.handleClick(this.multipleSelection[i]);
        }
        this.isShowTc = false;
      }
    },

    //新增订单
    async createBill(pay) {
      //生成订单号
      let orderNum = "";
      let num = String(formatZero(this.$store.state.userinfo.id, 7));
      let orderlist = await QUERYED(
        "post",
        "",
        "  ALLBill(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     time  orderNum   }"
      );
      if (orderlist.data.ALLBill.length < 1) {
        orderNum = getTimeFormat(new Date()) + num + "001";
      } else {
        for (let i = 0; i < orderlist.data.ALLBill.length; i++) {
          if (
            getTimeFormat(new Date()) ==
            getTimeFormat(orderlist.data.ALLBill[i].time)
          ) {
            let sic = orderlist.data.ALLBill[0].orderNum.substr(-3) * 1 + 1;
            orderNum =
              getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
            console.log("2", orderNum);
            break;
          } else {
            orderNum = getTimeFormat(new Date()) + num + "001";
          }
        }
      }

      //购买者支出订单
      await INSERT(
        "post",
        "",
        'insert_bill(objects: {type: 2, state: 0, time: "' +
        new Date() +
        '", userid: ' +
        this.$store.state.userinfo.id +
        ', orderNum: "' +
        orderNum +
        '", orderid: ' +
        1 + //数据库id
          ', Price: "' +
          pay +
          '", evaluate: "",classification:1}) {affected_rows returning {id}}'
      );

      return;
      //数据库拥有者收入订单
      await INSERT(
        "post",
        "",
        'insert_bill(objects: {type: 2, state: 0, time: "' +
        new Date() +
        '", userid: ' +
        1 + //用户id
        ', orderNum: "' +
        orderNum +
        '", orderid: ' +
        1 + //数据库id
          ', Price: "' +
          pay +
          '", evaluate: "",classification:0}) {affected_rows returning {id}}'
      );
    },

    //数据库拥有者发送一条消息
    async createMessage(pay) {
      return;
      await INSERT(
        "post",
        "",
        "insert_MessageNotification(objects: {type: 0,message:'您有收入'" +
          pay +
          ', creatime: "' +
          new Date() +
          '", userid: ' +
          this.checkBox[j] +
          "}) {affected_rows  }"
      );
    },

    //下载数据
    downLoadData() {
      this.isShowTc = false;
      let fileData =
        "https://image.baidu.com/search/detail?ct=503316480&z=undefined&tn=baiduimagedetail&ipn=d&word=%E5%9B%BE%E7%89%87&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=undefined&hd=undefined&latest=undefined&copyright=undefined&cs=2676935521,922112450&os=2627456264,2194281330&simid=4192512960,639456606&pn=2&rn=1&di=13230&ln=1669&fr=&fmq=1616741478933_R&fm=&ic=undefined&s=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&is=0,0&istype=0&ist=&jit=&bdtype=11&spn=0&pi=0&gsm=0&hs=2&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Fp1-q.mafengwo.net%252Fs7%252FM00%252F25%252FE2%252FwKgB6lPh4UeARKPpAABh4pruLDc72.jpeg%253FimageMogr2%25252Fthumbnail%25252F%2521310x207r%25252Fgravity%25252FCenter%25252Fcrop%25252F%2521310x207%25252Fquality%25252F90%26refer%3Dhttp%253A%252F%252Fp1-q.mafengwo.net%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1619333478%26t%3Dea124472e25d52b0b9bd895b8c8c7143&rpstart=0&rpnum=0&adpicid=0&force=undefined";
      const url = window.URL.createObjectURL(
        new Blob([fileData], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "下载文件名称"); // 下载文件的名称及文件类型后缀
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
      this.isShowTc = false;
    },

    //返回上一页
    topRouter() {
      console.log(this.id, 123);
      this.$router.push({
        path: "/Knowledge_introduction",
        query: { id: this.id },
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.downLoadNum = this.multipleSelection.length;
      console.log(this.multipleSelection);
    },
    handleSizeChange(val) {
      console.log("1", val);
      this.queryInfo.pagesize = val;
    },
    handleCurrentChange(val) {
      console.log("2", val);
      this.queryInfo.pagenum = val;
      this.init();
    },
    handleCheckAllChange(val) {
      console.log(val);

      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;

      this.downLoadNum1 = this.checkedCities.length;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;

      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
      this.downLoadNum1 = this.checkedCities.length;
    },
    delects() {
      this.checkedCities = [];
      this.checkAll = false;
      this.downLoadNum1 = this.checkedCities.length;
    },
    mfdownload() {
      for (let i = 0; i < this.checkedCities.length; i++) {
        this.handleClick(this.checkedCities[i]);
      }
    },
  },
};
</script>

<style src="../../../styles/public.css" scoped></style>
<style scoped>
.pado_top {
  height: 40px;
}
.top {
  margin: 0 100px 0 130px;
  height: 100%;
}
.elIcon {
  margin: 0 5px;
  font-size: 16px;
  color: #666;
}
.pado_search {
  height: 220px;
  background-color: #0084ff;
}
.topText {
  padding-top: 30px;
  font-size: 24px;
  color: #fff;
}
.topText1 {
  margin-top: 16px;
  font-size: 16px;
  color: #fff;
}
.fxSty {
  flex-direction: column;
}
.pado_list {
  background-color: #f7f7f7;
  padding-bottom: 40px;
}
.top_list {
  margin: 0 100px 0 130px;
  padding: 0 10px;
  padding-top: 20px;
  background-color: #fff;
}
.el-table {
  margin: 0 100px 0 130px;
  padding: 0 10px;
  padding-top: 20px;
  background-color: #fff;
}
.el-checkbox-group {
  margin: 0 100px 0 130px;
  padding: 0 10px;
  padding-top: 20px;
  background-color: #fff;
}
.list_top {
  height: 44px;
  background-color: #f2f6f8;
  padding: 0 25px;
}
.top_right {
  font-size: 14px;
  color: #333;
}
.top_btn {
  width: 94px;
  height: 28px;
  background-color: #f7e0a5;
  border-radius: 3px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  color: #0075ff;
  cursor: pointer;
}
.top_btn:hover {
  opacity: 0.8;
}
.list_main {
  padding: 15px 10px;
  border-radius: 4px;
}
.list_main:nth-child(2n-1) {
  background-color: #eee;
}
.hoverList:hover {
  background-color: rgba(0, 132, 255, 0.1);
}
.bgcBor {
  border-radius: 0;
  background-color: #fff !important;
}
.bgcBor + .bgcBor {
  border-top: 1px solid #333;
}
.list_item10,
.list_item15 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
}
.list_item10 {
  width: 8%;
}
.list_item15 {
  width: 19%;
}
.list_item80 {
  width: 80%;
}
.list_load {
  color: #0084ff;
}
.list_load:hover {
  cursor: pointer;
}
.pado_bottom {
  height: 100px;
  border-top: 2px solid #fff;
}
.pado_tc {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.tc_main {
  width: 880px;
  padding: 15px;
  background-color: #fff;
}
.main_back {
  width: 100%;
  height: 30px;
}
.backImg {
  width: 30px;
  height: 30px;
  float: right;
  cursor: pointer;
}
.tc_text1 {
  padding-top: 60px;
  text-align: center;
  font-size: 30px;
  color: #333;
}
.tc_text2 {
  padding-top: 60px;
  font-size: 18px;
  color: #333;
  text-align: center;
}
.tc_text2 > span {
  font-size: 30px;
  color: #d87900;
}
.tc_btn {
  width: 148px;
  height: 40px;
  margin: 40px auto;
  background-color: #0084ff;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
}
.tc_btn:hover {
  cursor: pointer;
  opacity: 0.9;
}
.mfe {
  margin: 0 100px 0 130px;
}
.vc {
  width: 100%;
  border-bottom: 1px solid #1b1b1b;
  padding: 5px;
  overflow: hidden;
}
.el-link.el-link--primary {
  float: right;
  right: 50px;
}
.setcr {
  overflow: hidden;
  width: 80%;
  float: left;
}
.setcr p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 500px;
}
.setcr .setcrct {
  white-space: inherit !important;
}
.el-checkbox,
.el-checkbox__input {
  white-space: inherit !important;
}
.el-checkbox-group >>> .el-checkbox__label {
  width: 90%;
  overflow: hidden;
}
.el-checkbox-group >>> .el-checkbox__input {
  float: left;
  margin-top: 2px;
  margin-left: 40px;
}
</style>
